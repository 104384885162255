import React from "react";
import { graphql, Link } from "gatsby";
import { gql } from "@apollo/client";
import { format } from "date-fns";

import { unwrap } from "../components/WagtailWrapper";
import { ProseRichText } from "../components/RichText";
import {
  Grid,
  Content,
  TightContent,
  Section,
  SmallGrid,
  Frame,
} from "../components/Layouts";
import { MinimalHeader } from "../components/MinimalHeader";
import { InstitutionImage } from "../components/Images";
import {
  PersonCard,
  PersonCardFragment,
  FoundationCardFragment,
  FoundationCard,
} from "../components/Card";
import { Prose, Title } from "../components/Prose";
import { Image } from "../components/Image";
import { Breadcrumbs } from "../components/SEOTags";
import { Facet } from "../components/Facets";
import { colorList } from "../components/Colors";

export const Component = ({ page }) => {
  const leadership = page?.leadership.filter(
    (l) =>
      l?.leadershipTypes?.filter((t) =>
        ["Principal Investigator"].includes(t.name)
      ).length > 0
  );
  const team = page?.leadership.filter(
    (l) =>
      l?.leadershipTypes?.filter((t) =>
        ["Principal Investigator"].includes(t.name)
      ).length === 0
  );
  return (
    <Frame page={page} url={`institutions/${page?.slug}`} title={page?.title}>
      <Section>
        <Content>
          <header>
            <Breadcrumbs
              url={"institutions"}
              list={"Member Institutions"}
              title={page?.title}
            />
            <div className="sm:flex sm:flex-row-reverse justify-between items-center">
              {page?.logo ? (
                <Image
                  src={page?.logo?.src}
                  alt={page?.logo?.title}
                  className="my-6 h-16"
                  height={100}
                />
              ) : (
                <div />
              )}
              <Title>{page?.title}</Title>
            </div>
            {page?.location && (
              <div className="mt-2 flex items-center text-sm leading-5  sm:mr-6">
                <svg
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  />
                </svg>
                {page?.location}
              </div>
            )}
            {page?.dateJoined && (
              <div className="mt-3 flex items-center">
                <svg
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="mr-1">Joined on</span>
                {page?.dateJoined && (
                  <time dateTime={page?.dateJoined}>
                    {format(new Date(page?.dateJoined), "MMMM dd, yyyy")}
                  </time>
                )}
              </div>
            )}
            {page?.memberTypes?.map((h) => (
              <Link key={h?.name} to={`/institutions?filters=${h?.name}`}>
                <Facet color={colorList[(h?.name).length % colorList.length]}>
                  {h?.name}
                </Facet>
              </Link>
            ))}
          </header>
        </Content>
      </Section>
      <div className="bg-gray-100">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-white"></div>
          <div className="relative max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden">
              <div className="bg-white relative w-full h-64">
                <div className="absolute h-full w-full object-cover">
                  <InstitutionImage image={page?.image} width={700} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Section theme="dark" shadow={false}>
        {!!(page?.subjects || page?.aliquots || page?.projects) && (
          <TightContent>
            <div className="mb-12 overflow-hidden flex justify-center">
              <dl className=" flex space-x-8">
                <div className="flex flex-col ">
                  <dt className="order-2 text-base leading-6 font-medium ">
                    Participants Enrolled
                  </dt>
                  <dd className="order-1 text-2xl leading-8 font-extrabold sm:text-3xl sm:leading-9">
                    {page?.subjects}
                  </dd>
                </div>
                <div className="flex flex-col">
                  <dt className="order-2 text-base leading-6 font-medium ">
                    Aliquots
                  </dt>
                  <dd className="order-1 text-2xl leading-8 font-extrabold sm:text-3xl sm:leading-9">
                    {page?.aliquots}
                  </dd>
                </div>
                <div className="flex flex-col">
                  <dt className="order-2 text-base leading-6 font-medium ">
                    Project Proposals
                  </dt>
                  <dd className="order-1 text-2xl leading-8 font-extrabold sm:text-3xl sm:leading-9">
                    {page?.projects}
                  </dd>
                </div>
              </dl>
            </div>
          </TightContent>
        )}

        {leadership?.length > 0 && (
          <TightContent>
            <MinimalHeader subTitle="cbtn" title="Principal Investigators" />
            <SmallGrid>
              {leadership.map((teamMember) => (
                <PersonCard
                  key={teamMember.slug}
                  item={teamMember}
                  info={false}
                />
              ))}
            </SmallGrid>
          </TightContent>
        )}

        <TightContent>
          {page?.description && (
            <>
              <MinimalHeader subTitle="about this" title="Institution" />
              <ProseRichText html={page?.description} />
            </>
          )}
          <Prose>
            {page?.contact && (
              <>
                <h3>Contact</h3>
                <p>{page?.contact}</p>
              </>
            )}
            {page?.website && (
              <a
                className="text-blue-900 hover:underline transition duration-150 ease-in-out"
                href={page?.website}
              >
                {page?.website}
              </a>
            )}
          </Prose>
        </TightContent>
      </Section>
      {page?.foundationPartnerships?.length > 0 && (
        <Section>
          <TightContent>
            <MinimalHeader title="CBTN participation made possible by" />
            <SmallGrid>
              {page?.foundationPartnerships.map(({ foundationPartnership }) => (
                <FoundationCard
                  key={foundationPartnership.slug}
                  item={foundationPartnership}
                />
              ))}
            </SmallGrid>
          </TightContent>
        </Section>
      )}
      {team?.length > 0 && (
        <Section>
          <Content>
            <MinimalHeader subTitle="meet the" title="Team" />
            <Grid>
              {team.map((teamMember) => (
                <PersonCard
                  key={teamMember.slug}
                  item={teamMember}
                  info={false}
                />
              ))}
            </Grid>
          </Content>
        </Section>
      )}
    </Frame>
  );
};
export const previewQuery = gql`
  query previewMemberInstitutionPage($contentType: String!, $token: String!) {
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on MemberInstitutionPage {
        website
        image {
          title
          src
        }
        logo {
          title
          src
        }
        description
        short
        contact
        location
        dateJoined
        memberTypes {
          name
        }
        leadership {
          ...PersonCardFragment
        }
        subjects
        aliquots
        projects
        sponsors {
          name
          image {
            src
            title
          }
        }
        foundationPartnerships {
          foundationPartnership {
            ...FoundationCardFragment
          }
        }
      }
    }
  }

  ${PersonCardFragment}
  ${FoundationCardFragment}
`;

export const query = graphql`
  query MemberInstitutionPage($id: Int) {
    wagtail {
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_MemberInstitutionPage {
          website
          image {
            title
            src
          }
          logo {
            title
            src
          }
          description
          short
          contact
          location
          dateJoined
          memberTypes {
            name
          }
          leadership {
            ...Wagtail_PersonCardFragment
          }
          subjects
          aliquots
          projects
          sponsors {
            name
            image {
              src
              title
            }
          }
          foundationPartnerships {
            foundationPartnership {
              ...Wagtail_FoundationCardFragment
            }
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
