import * as React from "react";

import { Container } from "./Layouts";
import { Smallcaps } from "./Prose";

export const MinimalHeader = ({
  Right,
  subTitle,
  title,
}: {
  Right?: JSX.Element;
  subTitle: string;
  title: string;
}) => (
  <Container>
    <div className="mb-12 flex justify-between items-center">
      <div className="flex flex-col justify-center">
        {!!subTitle && <Smallcaps>{subTitle}</Smallcaps>}
        {!!title && (
          <h2 className="mt-2 text-4xl leading-9 tracking-tight sm:text-5xl sm:leading-10">
            {title}
          </h2>
        )}
      </div>
      {!!Right && Right}
    </div>
  </Container>
);
